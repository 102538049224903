import { fm } from 'utility/string';

export const customerHeaderItems = [
  {
    title: fm('bookings'),
    path: '/bookings',
  },
  {
    title: fm('invoices'),
    path: '/invoices',
  },
  {
    title: fm('profile'),
    path: '/profile',
  },
];
