import React, { useMemo, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, ValidatorInput, Alert, Container, Typography, Box } from 'components/UI';
import { fm } from 'utility/string';

export default function Signup (props) {
  const { signupData, auth } = props;

  useEffect(() => {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      value => value === signupData.password,
    );
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [signupData]);

  const [emailValidators, emailErrorMessages] = useMemo(() => [
    ['required', 'isEmail'],
    [fm('email_required'), fm('email_invalid')],
  ]);

  const [passwordValidators, passwordErrorMessages] = useMemo(() => [
    ['minStringLength:6'],
    [fm('password_6_char_required')],
  ]);

  const [confirmPasswordValidators, setConfirmpasswordErrorMessages] = useMemo(() => [
    ['isPasswordMatch'],
    [fm('confirm_password_must_match_password')],
  ]);

  return (
    <Container component="main" maxWidth={'xs'}>
      <Grid container justify="center" spacing={2}>
        <Grid xs={12} item>
          <Box mt={5}>
            <Typography component="h1" variant="h5" align="center">
              {fm('signup_page_title')}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <ValidatorForm
            onSubmit={props.handleSubmit}
            instantValidate={false}
          >
            <ValidatorInput
              label={fm('email')}
              onChange={props.handleChangeInput}
              name="email"
              fullWidth
              value={signupData.email}
              validators={emailValidators}
              errorMessages={emailErrorMessages}
              variant="outlined"
            />
            <Box mt={1}>
              <ValidatorInput
                label={fm('password')}
                onChange={props.handleChangeInput}
                name="password"
                fullWidth
                type="password"
                validators={passwordValidators}
                errorMessages={passwordErrorMessages}
                value={signupData.password}
                variant="outlined"
              />
            </Box>
            <Box mt={1}>
              <ValidatorInput
                fullWidth
                label={fm('repeat_password')}
                onChange={props.handleChangeInput}
                name="confirmPassword"
                type="password"
                validators={confirmPasswordValidators}
                errorMessages={setConfirmpasswordErrorMessages}
                value={signupData.confirmPassword}
                variant="outlined"
              />
            </Box>

            {auth.errorMessage && <Alert color="error">{auth.errorMessage}</Alert>}
            <Box mt={2}>
              <Button variant="contained" color="primary" type="submit" fullWidth >
                {fm('register')}
              </Button>
            </Box>
          </ValidatorForm>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={props.redirectToLogin}>
            {fm('already_have_account_login')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
