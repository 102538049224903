import camelize from 'camelize';
import { customerRequestType, customerRequestStatusType } from 'utility/enum';
import keyBy from 'lodash/fp/keyBy';
import flow from 'lodash/fp/flow';
import filter from 'lodash/fp/filter';
import { parseJSON } from '../utility';

export const getFormattedBookings = (data = {}) => ({
  filters: {
    page: data.page,
    perPage: data.per_page,
    totalRecords: data.total_records,
  },
  records: data.records.map(record => ({
    ...camelize(record),
    virtual: !record.id,
    requests: flow(
      requests => parseJSON(requests, []),
      filter(request => request.approveStatus === customerRequestStatusType.pending),
      keyBy(r => r.requestType),
    )(record.requests),
  })),
});

export const getBookingRequestBody = request => ({
  company_id: request.companyId,
  request: {
    request_type: request.requestType,
    ...(request.requestType === customerRequestType.status && {
      booking_status: request.bookingStatus,
      reason: request.reason,
    }),
    ...(request.requestType === customerRequestType.date && {
      previous_date: request.previousDate,
      current_date: request.currentDate,
    }),
    booking_id: request.bookingId,
    customer_id: request.customerId,
  },
});

export const getOrderRequestBody = request => ({
  company_id: request.companyId,
  request: {
    request_type: request.requestType,
    ...(request.requestType === customerRequestType.status && {
      order_status: request.bookingStatus,
      reason: request.reason,
    }),
    ...(request.requestType === customerRequestType.date && {
      previous_date: request.previousDate,
      current_date: request.currentDate,
    }),
    order_id: request.bookingId,
    customer_id: request.customerId,
  },
});

export const getVirtualOrderRequestBody = (request, orderId) => ({
  company_id: request.companyId,
  request: {
    request_type: request.requestType,
    ...(request.requestType === customerRequestType.status && {
      order_status: request.bookingStatus,
      reason: request.reason,
    }),
    ...(request.requestType === customerRequestType.date && {
      previous_date: request.previousDate,
      current_date: request.currentDate,
    }),
    order_id: orderId,
    customer_id: request.customerId,
  },
});

export const getFormattedBookingsApiReq = (filters = {}) => ({
  page: filters.page,
  per_page: filters.perPage,
  start_date: filters.startDate,
  company_id: filters.company.id,
});
