import React, { useState, useEffect } from 'react';
import { customerRequestType, constitutionMessageTypes } from 'utility/enum';
import { fm } from 'utility/string';
import { getStandardDate } from 'utility/dateTime';
import get from 'lodash/fp/get';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  InlineDatePicker,
} from 'components/UI';

export default function BookingDateChangeModal (props) {
  const { booking, setBookingFields, state, selectedCompany, user } = props;
  const request = booking.requests[customerRequestType.date] ||
    { currentDate: booking.startDate, previousDate: booking.startDate };
  const [currentDate, setCurrentDate] = useState(request.currentDate || booking.startDate);
  const [currentDateFixed, setFixCurrentDate] = useState(false);

  useEffect(() => {
    if (currentDateFixed || !get(`requests[${customerRequestType.date}]`)(booking)) return;
    setCurrentDate(booking.requests[customerRequestType.date].currentDate);
  }, [booking.requests]);

  const onEnter = () => {
    if (!request.id) return setFixCurrentDate(true);
    if (booking.order) {
      props.requestOrderChangeRequestDetails(request.id, selectedCompany.id);
    } else {
      props.requestBookingChangeRequestDetails(request.id, selectedCompany.id);
    }
  };

  const onClose = () => {
    setBookingFields({ showDateChangeModal: false });
  };

  const handleDeleteRequest = () => {
    props.setConfirmationDialogFields({
      open: true,
      title: fm('confirm_delete_request_title'),
      message: fm('confirm_delete_request_message'),
      callback: () => booking.order
        ? props.requestDeleteOrderChangeRequest(request, selectedCompany.id)
        : props.requestDeleteBookingChangeRequest(request, selectedCompany.id),
    });
  };

  const onSubmit = () => {
    onClose();
    const nextRequestData = {
      requestType: customerRequestType.date,
      previousDate: booking.startDate || booking.date,
      currentDate,
      bookingId: booking.id,
      companyId: selectedCompany.id,
      customerId: user.id,
      date: booking.date,
      projectId: booking.projectId,
    };

    if (request.id) {
      if (booking.order) {
        props.requestUpdateOrderChangeRequest(request, nextRequestData);
      } else {
        props.requestUpdateBookingChangeRequest(request, nextRequestData, selectedCompany.id);
      }
    } else if (booking.order) {
      if (booking.virtual) {
        props.requestVirtualOrderDateChange(nextRequestData, selectedCompany.id);
      } else {
        props.requestOrderDateChange(nextRequestData, selectedCompany.id);
      }
    } else {
      props.requestBookingDateChange(nextRequestData, selectedCompany.id);
    }
  };

  return (
    <Dialog open={state.showDateChangeModal} onEnter={onEnter} onClose={onClose}>
      <DialogTitle>
        {request.id ? fm('update_request_change_date') : fm('request_change_date')}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <Box mt={2}>
              <InlineDatePicker
                disabled
                label={fm('change_from')}
                value={request.previousDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <InlineDatePicker
                label={fm('change_to')}
                value={currentDate}
                onChange={setCurrentDate}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={1}>
              <small>
                {get(`messageRules.${constitutionMessageTypes.bookingDate}.title`)(selectedCompany)}
              </small>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="space-between">
          <Grid item>
            <Grid container justify="space-between">
              <Box ml={2}>
                <Button
                  disabled={getStandardDate(request.previousDate) === currentDate}
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={onSubmit}
                >
                  {fm('change_date')}
                </Button>
              </Box>
              <Box ml={1}>
                <Button variant="contained" color="default" size="medium" onClick={onClose}>
                  {fm('close')}
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            {request.id && (
              <Box mr={2}>
                <Button variant="text" color="secondary" size="medium" onClick={handleDeleteRequest}>
                  {fm('remove_request')}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
