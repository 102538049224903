import React, { useContext, useEffect, useCallback } from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  CustomizedSnackbars,
  Button,
  // IconButton,
  Divider,
} from 'components/UI';
// import { GetAppOutlinedIcon } from 'components/UI/icons';
import { fm } from 'utility/string';
import noop from 'lodash/fp/noop';
import { Context as InvoiceContext } from 'context/invoiceContext';
import { Context as AuthContext } from 'context/authContext';
import { Context as AppContext } from 'context/appContext';

const Invoices = () => {
  const { state: authState } = useContext(AuthContext);
  const { setConfirmationDialogFields } = useContext(AppContext);
  const { state, getCustomerInvoices, resetSnackBarData, setInvoiceFilters } = useContext(InvoiceContext);

  const getRecords = useCallback((filters) => {
    getCustomerInvoices(filters, authState.selectedCompany);
  }, [authState.selectedCompany]);

  useEffect(() => {
    getRecords(state.filters);
  }, []);

  const invoicePreview = (/* invoiceId */) => { // eslint-disable-line
    // getInvoicePreview(invoiceId);
    return setConfirmationDialogFields({
      open: true,
      infoModal: true,
      closeLabel: fm('close'),
      message: fm('fortnox_end_issue_cannot_process_request'),
      title: fm('fortnox_end_issue'),
      callback: noop,
    });
  };

  const setFilters = (filters) => {
    const newFilters = { ...state.filters, ...filters };
    setInvoiceFilters(newFilters);
    getRecords(newFilters);
  };

  const onClickLoadMore = () => {
    setFilters({ perPage: `${(parseInt(state.filters.perPage || '10', 10)) + 10}` });
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        {authState.user.integration && authState.user.exported ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                <Box fontWeight="fontWeightBold" mt={8}>{fm('invoice_page_heading')}</Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                {fm('invoice_list')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify="space-between" spacing={1}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={6} md={4}>
                      <Typography variant="body2"><Box fontWeight="bold" component="span">{fm('invoice_id')}</Box></Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography variant="body2"><Box fontWeight="bold" component="span">{fm('due_date')}</Box></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container justify="flex-end">
                    <Grid item xs={6} md={4}>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Typography variant="body2"><Box fontWeight="bold" component="span">{fm('total')}</Box></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider variant="fullWidth" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {state.customerInvoices.map(customerInvoice => (
                <>
                  <Grid container justify="space-between" spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          <Typography variant="body2">{customerInvoice.documentNumber}</Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Typography variant="body2"><Box>{customerInvoice.dueDate}</Box></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container justify="flex-end" alignItems="center">

                        <Grid item xs={6} md={4}>
                          {/* Due to error in Fortnox we hide this field
                    <Typography variant="body2">
                            <IconButton onClick={() => invoicePreview(customerInvoice.documentNumber)}>
                              <GetAppOutlinedIcon />
                            </IconButton>
                          </Typography> */}
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Typography variant="body2"><Box fontWeight="bold" component="span" fontSize={20}>{customerInvoice.amount}</Box> kr</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Box mb={5}>
                <Grid container justify="center">
                  <Button
                    disabled={Math.ceil(state.filters.totalRecords / state.filters.perPage) === state.filters.page}
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={onClickLoadMore}
                  >
                    {fm('load_more')}
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box mt={8}>
                <Typography>No Invoice found</Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Container>
      <CustomizedSnackbars snackbar={state.snackbar} resetSnackBarData={resetSnackBarData} />
    </>
  );
};

export default Invoices;
