import createDataContext from '../createDataContext';
import camelize from 'camelize';
import authReducer from './reducer';
import get from 'lodash/fp/get';
import axios from 'api/axios';
import { fm, deepTrim } from 'utility/string';
import { parseJSON } from '../utility';
import { appRoutes } from 'utility/enum';
import {
  setAuthError,
  resetSnackbar,
  setAuthFields,
  setSnackbarError,
  setSnackbarSuccess,
} from 'actions';
import {
  getFormattedUserDetails,
  getFormattedUserProfileUpdateApiReq,
  getResetPasswordRequestBody,
  getFormattedAvailableCompaniesData,
} from './selector';

const initialState = {
  language: localStorage.getItem('language') || 'se',
  user: JSON.parse(localStorage.getItem('user')) || {},
  isSignedIn: false,
  errorMessage: '',
  loading: false,
  token: localStorage.getItem('token'),
  availableCompanies: [],
  selectedCompany: parseJSON(localStorage.getItem('selectedCompany')),
  snackbar: {
    open: false,
    message: '',
  },
};

const getErrorCode = get('response.data.errors[0].details.code');

const errorCodes = {
  emailNotConfirmed: '5001',
};

const setLanguagePersist = dispatch => async (lang) => {
  dispatch(setAuthFields({ language: lang }));
  await localStorage.setItem('language', lang);
  window.location.reload();
};

const requestSignin = dispatch => async (signinCreds) => {
  try {
    const trimmedCreds = deepTrim(signinCreds);
    const { data: { data } } = await axios.post('/customer_login/login', trimmedCreds);
    await localStorage.setItem('token', data.customer_login.token);
    dispatch(setAuthFields({
      token: localStorage.getItem('token'),
      isSignedIn: true,
    }));
  } catch (err) {
    if (!err.response) return dispatch(setSnackbarError('snackbarAuthInternetError'));
    if (getErrorCode(err)) {
      dispatch(setAuthFields({ showSendConfirmationEmail: errorCodes.emailNotConfirmed === getErrorCode(err) }));
      const remainingLoginAttempts = get('response.data.errors[0].details.remaining_attempts')(err);
      dispatch(setAuthError(fm(getErrorCode(err), '', { values: { remainingLoginAttempts } })));
    }
  }
};

const requestSignup = dispatch => async (signupData, history) => {
  try {
    const customer = { customer_login: deepTrim(signupData) };
    await axios.post('/customer_login', customer);
    dispatch(setSnackbarSuccess('snackbar_signup_success'));
    history.push(appRoutes.login);
  } catch (err) {
    if (!err.response) return dispatch(setSnackbarError('snackbarAuthInternetError'));
    if (getErrorCode(err)) {
      dispatch(setAuthError(fm(getErrorCode(err))));
    }
  }
};

const setAuthFieldsThunk = dispatch => (payload) => {
  dispatch(setAuthFields(payload));
};

const setAuthErrorThunk = dispatch => (payload) => {
  dispatch(setAuthError(payload));
};

const signOut = () => () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('selectedCompany');
  window.location.reload();
};

const getUserProfile = dispatch => async (company) => {
  try {
    const params = { company_id: company.id };
    const res = await axios.get('/profile', { params });
    const { user } = getFormattedUserDetails(res.data.customer);
    localStorage.setItem('user', JSON.stringify(user));
    dispatch(setAuthFields({ user }));
  } catch (error) {
    dispatch(setSnackbarError('snackbarAuthGetUserProfileError'));
  }
};

const requestAvailableCompanies = dispatch => async () => {
  try {
    const { data: { data } } = await axios.get('/login_generated');
    const availableCompanies = getFormattedAvailableCompaniesData(camelize(data));
    dispatch(setAuthFields({ availableCompanies }));
  } catch (error) {
    dispatch(setSnackbarError('snackbarAuthGetUserProfileError'));
  }
};

const updateUserProfile = dispatch => async (data) => {
  try {
    const params = getFormattedUserProfileUpdateApiReq(data);
    await axios.put('/profile_update', { ...params });
    dispatch(setSnackbarSuccess('snackbarUserProfileUpdatedSuccess'));
  } catch (error) {
    dispatch(setSnackbarError('snackbarUserProfileUpdatedError'));
  }
};

const resetSnackBarData = dispatch => () => {
  dispatch(resetSnackbar());
};

const requestResetPasswordLink = dispatch => async (email, history) => {
  try {
    const requestBody = { customer_login: deepTrim({ email }) };
    await axios.post('/customer_login/reset_login', requestBody);
    dispatch(setSnackbarSuccess('snackbarUserEmailSuccess'));
    history.push(appRoutes.login);
  } catch (error) {
    dispatch(setSnackbarError('snackbarResendPasswordError'));
    if (getErrorCode(error)) {
      dispatch(setAuthError(fm(getErrorCode(error))));
    }
  }
};

const requestSendConfirmationEmail = dispatch => async (email) => {
  try {
    const requestBody = deepTrim({ email });
    await axios.post('/customer_login/send_confirmation', requestBody);
    dispatch(setSnackbarSuccess('snackbar_confirmation_email_sent'));
    dispatch(setAuthFields({ showSendConfirmationEmail: false }));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_confirmation_email_error'));
    if (getErrorCode(error)) {
      dispatch(setAuthError(fm(getErrorCode(error))));
    }
  }
};

const requestChangePassword = dispatch => async (data, history) => {
  try {
    const requestBody = getResetPasswordRequestBody(data);
    await axios.post('/customer_login/recover_login', requestBody);
    dispatch(setSnackbarSuccess('snackbar_reset_password_success'));
    history.push(appRoutes.login);
  } catch (error) {
    dispatch(setSnackbarError('snackbar_reset_password_failed'));
    if (getErrorCode(error)) {
      dispatch(setAuthError(fm(getErrorCode(error))));
    }
  }
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    setLanguagePersist,
    requestSignin,
    signOut,
    getUserProfile,
    requestAvailableCompanies,
    updateUserProfile,
    resetSnackBarData,
    requestResetPasswordLink,
    requestChangePassword,
    requestSignup,
    requestSendConfirmationEmail,
    setAuthError: setAuthErrorThunk,
    setAuthFields: setAuthFieldsThunk,
  },
  initialState,
);
