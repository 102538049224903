import React, { useCallback } from 'react';
import { fm } from 'utility/string';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from 'components/UI';

export default function ConfirmationDialog (props) {
  const { confirmationDialog } = props;

  const handleClose = useCallback(() => {
    props.setConfirmationDialogFields({ open: false });
  }, []);

  const onExit = useCallback(() => {
    props.setConfirmationDialogFields({ infoModal: false, closeLabel: undefined });
  }, []);

  const handleClickAccept = useCallback(() => {
    if (typeof confirmationDialog.callback === 'function') {
      confirmationDialog.callback();
      props.setConfirmationDialogFields({ open: false });
    } else {
      throw Error('Confirmation dialog callback is not a function.');
    }
  }, [confirmationDialog.callback]);

  return (
    <Dialog
      open={confirmationDialog.open}
      onClose={handleClose}
      onExit={onExit}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">
        {confirmationDialog.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {confirmationDialog.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!confirmationDialog.infoModal && (
          <Button onClick={handleClickAccept} color="primary" variant="contained">
            {confirmationDialog.acceptLabel}
          </Button>
        )}
        <Button onClick={handleClose} color="primary" autoFocus>
          {confirmationDialog.closeLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.defaultProps = {
  confirmationDialog: {
    acceptLabel: fm('yes'),
    closeLabel: fm('no'),
  },
};
