import React, { useContext, useEffect, useCallback } from 'react';
import BookingList from 'views/Bookings';
import { Context as BookingContext } from 'context/bookingContext';
import { Context as AuthContext } from 'context/authContext';

export default function BookingListContainer () {
  const { state: authState } = useContext(AuthContext);
  const {
    state,
    setBookingFilters,
    setBookingFields,
    resetSnackBarData,
    getCustomerCompanyBookings,
  } = useContext(BookingContext);

  const getRecords = useCallback(() => {
    getCustomerCompanyBookings(state.filters, authState.selectedCompany);
  }, [state.filters, authState.selectedCompany]);

  useEffect(() => {
    getRecords();
  }, [authState.selectedCompany, state.refreshAllBookings, state.filters.startDate, state.filters.perPage]);

  const setFilters = useCallback((filters) => {
    const newFilters = { ...state.filters, ...filters };
    setBookingFilters(newFilters);
  }, [state.filters]);

  const handleDateChange = useCallback((startDate) => {
    setFilters({ startDate });
  }, []);

  const onClickLoadMore = useCallback(() => {
    setFilters({ perPage: `${(parseInt(state.filters.perPage || '10', 10)) + 10}` });
  }, [state.filters.perPage]);

  return (
    <BookingList
      bookings={state}
      user={authState.user}
      setBookingFields={setBookingFields}
      handleDateChange={handleDateChange}
      onClickLoadMore={onClickLoadMore}
      resetSnackBarData={resetSnackBarData}
    />
  );
}
