import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Typography, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction } from 'components/UI';
import { BusinessIcon, NavigateNextIcon } from 'components/UI/icons';
import { fm } from 'utility/string';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

export default function CompanyList (props) {
  const { auth, onClickSignOut } = props;
  return (
    <Dialog open>
      <DialogTitle>
        {fm('available_companies')}
      </DialogTitle>
      <DialogContent dividers>
        {auth.availableCompanies.length > 0 ? auth.availableCompanies.map(company => (
          <React.Fragment key={company.id}>
            <List onClick={() => props.onSelectCompany(company)}>
              <ListItem button>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={company.name}/>
                <ListItemSecondaryAction>
                  <NavigateNextIcon />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </React.Fragment>
        )) : (
          <React.Fragment>
            {fm('no_company_found_contact_service_provider')}
            <Typography onClick={onClickSignOut}>
              <Button color="secondary" startIcon={<ExitToAppIcon />}>{fm('booking.logout', 'Log out')}</Button>
            </Typography>
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
}
