export const appRoutes = {
  home: '/',
  bookings: '/bookings',
  login: '/login',
  signup: '/signup',
  profile: '/profile',
  invoices: '/invoices',
  cookiePolicy: '/cookie-policy',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  companiesList: '/companies-list',
};

export const permissionTypeFieldNames = {
  200: 'canCustomerViewInvoices',
  201: 'canCustomerViewProfile',
  202: 'canCustomerViewBookingTime',
};

export const bookingStatusTypes = {
  active: 'Active',
  cancelled: 'Canceled',
  completed: 'Completed',
};

export const customerRequestType = {
  status: 1,
  date: 2,
};

export const customerRequestBookingStatusType = {
  active: 1,
  cancelled: 2,
  completed: 3,
};

export const customerRequestStatusType = {
  pending: 1,
  approved: 2,
  denied: 3,
};

export const constitutionMessageTypes = {
  bookingDate: 1,
  bookingStatus: 2,
};
