import React, { useEffect, useState, useMemo } from 'react';
import { customerRequestType, customerRequestBookingStatusType, constitutionMessageTypes } from 'utility/enum';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { fm } from 'utility/string';
import get from 'lodash/fp/get';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Box,
  InlineDatePicker,
  ValidatorInput,
} from 'components/UI';

export default function BookingCancelRequestModal (props) {
  const { booking, setBookingFields, state, selectedCompany, user } = props;
  const [reason, setReason] = useState('');
  const [reasonFixed, setFixReason] = useState(false);
  const request = booking.requests[customerRequestType.status] || {};

  const onEnter = () => {
    if (!request.id) return setFixReason(true);
    if (booking.order) {
      props.requestOrderChangeRequestDetails(request.id, selectedCompany.id);
    } else {
      props.requestBookingChangeRequestDetails(request.id, selectedCompany.id);
    }
  };

  useEffect(() => {
    if (reasonFixed || !get(`requests[${customerRequestType.status}]`)(booking)) return;
    setReason(booking.requests[customerRequestType.status].reason);
  }, [booking.requests]);

  const onClose = () => {
    setBookingFields({ showCancelBookingModal: false });
  };

  const onSubmit = () => {
    const nextRequest = {
      reason,
      requestType: customerRequestType.status,
      bookingStatus: customerRequestBookingStatusType.cancelled,
      bookingId: booking.id,
      companyId: selectedCompany.id,
      customerId: user.id,
      date: booking.date,
      projectId: booking.projectId,
    };
    if (request.id) {
      if (booking.order) {
        props.requestUpdateOrderChangeRequest(request, nextRequest);
      } else {
        props.requestUpdateBookingChangeRequest(request, nextRequest, selectedCompany.id);
      }
    } else {
      if (booking.order) {
        if (booking.virtual) {
          props.requestCancelVirtualOrder(nextRequest, selectedCompany.id);
        } else {
          props.requestCancelOrder(nextRequest, selectedCompany.id);
        }
      }
      props.requestCancelBooking(nextRequest, selectedCompany.id);
    }
  };

  const handleDeleteRequest = () => {
    props.setConfirmationDialogFields({
      open: true,
      title: fm('confirm_delete_request_title'),
      message: fm('confirm_delete_request_message'),
      callback: () => booking.order
        ? props.requestDeleteOrderChangeRequest(request, selectedCompany.id)
        : props.requestDeleteBookingChangeRequest(request, selectedCompany.id),
    });
  };

  const [reasonValidators, reasonErrorMessages] = useMemo(() => [
    ['required'],
    [fm('reason_required')],
  ]);

  return (
    <Dialog open={state.showCancelBookingModal} onEnter={onEnter} onClose={onClose}>
      <DialogTitle>
        {request.id ? fm('update_request_cancel_booking') : fm('request_cancel_booking')}
      </DialogTitle>
      <ValidatorForm
        onSubmit={onSubmit}
        instantValidate={false}
      >
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Box mt={2}>
                <InlineDatePicker
                  disabled
                  label={fm('change_from')}
                  value={booking.startDate}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <ValidatorInput
                  label={fm('reason_comments')}
                  onChange={({ target }) => setReason(target.value)}
                  name="reason"
                  fullWidth
                  value={reason}
                  validators={reasonValidators}
                  errorMessages={reasonErrorMessages}
                  variant="outlined"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1}>
                <small>
                  {get(`messageRules.${constitutionMessageTypes.bookingStatus}.title`)(selectedCompany)}
                </small>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              <Grid container justify="space-between">
                <Box ml={2}>
                  <Button variant="contained" color="secondary" size="medium" onClick={onSubmit}>
                    {fm('cancel_booking')}
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button variant="contained" color="default" size="medium" onClick={onClose}>
                    {fm('close')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              {request.id && (
                <Box mr={2}>
                  <Button variant="text" color="secondary" size="medium" onClick={handleDeleteRequest}>
                    {fm('remove_request')}
                  </Button>
                </Box>
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}
