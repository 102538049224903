import React from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en.js';
import se from 'react-intl/locale-data/se.js';
import messagesSe from 'translations/se';
import messagesEn from 'translations/en';
import Routes from 'Routes';
import { Provider as AuthProvider } from 'context/authContext';
import { Provider as InvoiceProvider } from 'context/invoiceContext';
import { Provider as BookingProvider } from 'context/bookingContext';
import { Provider as AppProvider } from 'context/appContext';
import { ConfirmationDialog } from 'containers/common';

addLocaleData([...en, ...se]);

const messages = {
  'se': messagesSe,
  'en': messagesEn,
};

function App () {
  const language = localStorage.getItem('language') || 'se';

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <AppProvider>
        <AuthProvider>
          <InvoiceProvider>
            <BookingProvider>
              <Routes />
              <ConfirmationDialog />
            </BookingProvider>
          </InvoiceProvider>
        </AuthProvider>
      </AppProvider>
    </IntlProvider>
  );
}

export default App;
