import React, { useContext, useEffect, useCallback } from 'react';
import CompanyList from 'views/CompanyList';
import { Context as AuthContext } from 'context/authContext';
import { appRoutes } from 'utility/enum';

export default function CompanyListContainer (props) {
  const { state, requestAvailableCompanies, setAuthFields, signOut } = useContext(AuthContext);

  useEffect(() => {
    requestAvailableCompanies();
  }, []);

  const onSelectCompany = useCallback((company) => {
    setAuthFields({ selectedCompany: company });
    localStorage.setItem('selectedCompany', JSON.stringify(company));
    props.history.push(appRoutes.bookings);
  }, []);

  return (
    <CompanyList
      auth={state}
      onSelectCompany={onSelectCompany}
      onClickSignOut={signOut}
    />
  );
}
