import React from 'react';
import { Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from 'components/UI';
import { options } from './configs';

export default function RequestChangePopper (props) {
  const { anchorRef, setBookingFields, open, booking } = props;

  const handleClickOption = (getFields) => {
    setBookingFields({ ...getFields(), booking });
  };

  return (
    <Popper open={open} anchorEl={anchorRef.current} placement="left-start" role={undefined} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={props.handleClose}>
              <MenuList id="split-button-menu">
                {options.map(option => (
                  <MenuItem onClick={() => handleClickOption(option.getFields)}>
                    {option.title}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
