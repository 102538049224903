import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';

export default function ValidatorInput (props) {
  const {
    label,
    onChange,
    name,
    value,
    validators,
    errorMessages,
    type,
    variant,
    fullWidth,
  } = props;

  return (
    <TextValidator
      label={label}
      onChange={onChange}
      name={name}
      value={value}
      validators={validators}
      errorMessages={errorMessages}
      type={type}
      variant={variant}
      fullWidth={fullWidth}
      InputLabelProps={{ shrink: Boolean(value) }}
    />
  );
}
