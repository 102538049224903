import React from 'react';
import InlineDatePicker from 'components/UI/DatePicker';
import { fm } from 'utility/string';
import { getStandardDate } from 'utility/dateTime';
import { BookingDateChangeModal, BookingCancelRequestModal } from 'containers/Bookings';
import BookingRow from './BookingRow';
import {
  Box,
  Button,
  Container,
  CustomizedSnackbars,
  Grid,
  Typography,
  CircularProgress,
} from 'components/UI';

export default function Bookings (props) {
  const { bookings, user } = props;
  const { filters, loading } = bookings;

  return loading ? (
    <Container component="main" maxWidth="sm">
      <Grid container justify="center">
        <Box mt={20}>
          <Box display="flex" justifyContent="center"><CircularProgress /></Box>
          <Typography variant="h5">{fm('loading')}</Typography>
        </Box>
      </Grid>
    </Container>
  ) : (
    <>
      <Container component="main" maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={12}>
              <InlineDatePicker
                label={fm('select_date', 'Select Date')}
                value={bookings.filters.startDate}
                onChange={props.handleDateChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              <Box fontSize={20} fontWeight="bold" component="span">{fm('booking_page_ongoing_booking')}</Box>
            </Typography>
          </Grid>
          {bookings.records.map(booking => booking.startDate !== getStandardDate() ? null : (
            <BookingRow booking={booking} user={user} setBookingFields={props.setBookingFields} />
          ))}

          <Grid item xs={12}>
            <Typography variant="body2">
              <Box fontSize={20} fontWeight="bold" component="span">{fm('booking_page_other_booking')}</Box>
            </Typography>
          </Grid>
          {bookings.records.map(booking => booking.startDate === getStandardDate() ? null : (
            <BookingRow booking={booking} user={user} setBookingFields={props.setBookingFields} />
          ))}
          <Grid item xs={12}>
            <Box mb={5}>
              <Grid container justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={filters.totalRecords <= (filters.page * filters.perPage)}
                  onClick={props.onClickLoadMore}
                >
                  {fm('load_more')}
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <CustomizedSnackbars
        snackbar={bookings.snackbar}
        resetSnackBarData={props.resetSnackBarData}
      />
      <BookingDateChangeModal />
      <BookingCancelRequestModal />
    </>
  );
}
