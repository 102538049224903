import seLocale from 'date-fns/locale/sv';
import format from 'date-fns/format';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import differenceInHours from 'date-fns/differenceInHours';
import getUnixTime from 'date-fns/getUnixTime';
import setDateFns from 'date-fns/set';

const locales = {
  'se': seLocale,
};

export const epochUnixTimeStamp = -19800000;
export const dateZero = new Date(epochUnixTimeStamp);

export const formatStrings = {
  defaultDate: 'yyyy MMM dd',
  defaulTime: 'HH:mm',
  dateSpaceTime: 'yyyy MMM dd HH:mm',
  punchTimeFormat: 'HH:mm:ss',
  dateDashTime: 'yyyy MMM dd - HH:mm',
  dashDateDashTime: 'yyyy-MM-dd - HH:mm',
  filtersDate: 'yyyy-MM-dd',
};

export const getDifferenceBetweenTime = (time1, time2) => {
  const [hours1, mins1] = (time1).split(':');
  const [hours2, mins2] = (time2).split(':');
  const dateTime1 = setDateFns(new Date(0), { hours: hours1, minutes: mins1 });
  const dateTime2 = setDateFns(new Date(0), { hours: hours2, minutes: mins2 });
  const minutes = differenceInMinutes(dateTime1, dateTime2) % 60 || 0;
  const hours = differenceInHours(dateTime1, dateTime2) || 0;
  const hoursString = (hours > 0 || (hours === 0 && minutes >= 0))
    ? `${hours < 10 ? `0${hours}` : hours}`
    : `${hours > -10 ? `-0${Math.abs(hours)}` : hours}`;

  const minsString = `${Math.abs(minutes) < 10 ? `0${Math.abs(minutes)}` : Math.abs(minutes)}`;

  return `${hoursString}:${minsString}`;
};

export const getDiffInHoursAndMins = (endDate, startDate) => {
  const startDateZero = startDate || dateZero;
  const minutes = Math.abs(differenceInMinutes(endDate, startDateZero)) % 60 || 0;
  const hours = Math.abs(differenceInHours(endDate, startDateZero)) || 0;
  return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
};

export const getHourMinutesInUnixTime = (time) => {
  if (!time) return null;
  const [hours = 0, mins = 0] = time.split(':');
  return getUnixTime(new Date(0).setUTCHours(hours, mins));
};

export const getUnixTimeInHoursMinutes = (time) => {
  if (!time) return null;
  return getDiffInHoursAndMins(new Date(time * 1000), new Date(0));
};

export const formatDate = (date, formatStyle) => {
  const locale = locales[localStorage.language];
  return format(new Date(date), formatStyle, { locale });
};

export const getStandardDate = (inputDate) => {
  const date = inputDate ? new Date(inputDate) : new Date();
  return formatDate(date, 'yyyy-MM-dd');
};
