import React from 'react';
import { MenuItem, FormControl, Select } from '../index';

export default function ToolBarSelect (props) {
  const { onChange, value, options } = props;

  return (
    <FormControl size="small" variant="outlined" margin="dense">
      <Select
        value={value}
        disableUnderline
        onChange={onChange}
        defaultValue="active"
      >
        {options.map((op, idx) => <MenuItem key={`select-op-${idx}`} value={op.value}>{op.label}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
