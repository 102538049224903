const getFormattedInvoice = record => ({
  documentNumber: record.DocumentNumber,
  dueDate: record.DueDate,
  amount: record.Total,
  currency: record.Currency,
  sent: record.Sent,
});

export const getFormattedCustomerInvoices = (data = {}) => ({
  filters: {
    page: data.page,
    perPage: data.per_page,
    totalRecords: data.total_records,
  },
  customerInvoices: data.records.map(getFormattedInvoice),
});

export const getFormattedInvoiceApiReq = data => ({
  page: data.page,
  per_page: data.perPage,
  company_id: data?.company?.id,
});
