import React from 'react';
import { arrayOf, string, func, number, oneOfType, object, bool } from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import styles from 'components/styles/mainHeader.module.scss';

const NavTabs = withStyles({
  root: styles.mainNavTab,
  indicator: {
    backgroundColor: '#353E46',
    height: '4px',
  },
})(Tabs);

const NavTab = withStyles(theme => ({
  root: {
    textTransform: 'uppercase',
    minWidth: 40,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
    color: '#353E46',
    opacity: '0.5',
    '&:hover': {
      color: '#353E46',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&$selected': {
      color: '#353E46',
      opacity: 1,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#353E46',
      opacity: 1,
    },
  },
  selected: {},
}))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const TabNavBar = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <NavTabs
        value={props.activeTab}
        onChange={props.setActiveTab}
        aria-label={`${props.id}-tabs-nav`}
        indicatorColor={props.indicatorColor || 'secondary'}
        textColor={props.textColor || 'secondary'}
      >
        {props.tabs.map((tab, idx) => (
          <NavTab classes={{ root: styles.mainNavTab }} label={tab} key={`${props.id}-${idx}`} />
        ))}
      </NavTabs>
    </div>
  );
};

TabNavBar.propTypes = {
  tabs: arrayOf(oneOfType([string, object])),
  setActiveTab: func,
  activeTab: oneOfType([number, bool]),
};

export default TabNavBar;
