import React, { useContext } from 'react';
import { Context as BookingContext } from 'context/bookingContext';
import { Context as AppContext } from 'context/appContext';
import BookingCancelRequestModal from 'components/Booking/BookingCancelRequestModal';
import { Context as AuthContext } from 'context/authContext';

export default function BookingDateChangeModalContainer () {
  const { state, setBookingFields, requestCancelBooking, ...bookingContext } = useContext(BookingContext);
  const { state: { selectedCompany, user } } = useContext(AuthContext);
  const { setConfirmationDialogFields } = useContext(AppContext);

  if (!state.showCancelBookingModal) {
    return null;
  }

  return (
    <BookingCancelRequestModal
      state={state}
      selectedCompany={selectedCompany}
      user={user}
      booking={state.booking}
      setConfirmationDialogFields={setConfirmationDialogFields}
      setBookingFields={setBookingFields}
      requestCancelBooking={requestCancelBooking}
      requestCancelOrder={bookingContext.requestCancelOrder}
      requestCancelVirtualOrder={bookingContext.requestCancelVirtualOrder}
      requestBookingChangeRequestDetails={bookingContext.requestBookingChangeRequestDetails}
      requestOrderChangeRequestDetails={bookingContext.requestOrderChangeRequestDetails}
      requestDeleteBookingChangeRequest={bookingContext.requestDeleteBookingChangeRequest}
      requestDeleteOrderChangeRequest={bookingContext.requestDeleteOrderChangeRequest}
      requestUpdateBookingChangeRequest={bookingContext.requestUpdateBookingChangeRequest}
      requestUpdateOrderChangeRequest={bookingContext.requestUpdateOrderChangeRequest}
    />
  );
}
