import React, { useState, useRef } from 'react';
import { fm } from 'utility/string';
import { customerRequestType } from 'utility/enum';
import { formatDate, formatStrings } from 'utility/dateTime';
import { HistoryIcon } from 'components/UI/icons';
import { FormattedMessage } from 'react-intl';
import {
  Popover,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Tooltip,
} from 'components/UI';

const anchorOrigin = { vertical: 'bottom', horizontal: 'left' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

export default function RequestHistoryPopover (props) {
  const [open, setOpen] = useState(false);
  const iconButtonRef = useRef(false);
  const { requestHistory: records, booking, selectedCompany } = props;

  const getRecords = () => {
    if (booking.order) {
      props.requestOrderRequestsHistory(booking.id, selectedCompany.id);
    } else {
      props.requestBookingRequestsHistory(booking.sequenceNum, selectedCompany.id);
    }
  };

  const onEnter = () => {
    getRecords();
  };

  const togglePopover = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const getMessage = request => ({
    [customerRequestType.date]: (
      <FormattedMessage
        id="request_history_booking_date_changed"
        values={{
          fromDate: formatDate(request.previousDate, formatStrings.defaultDate),
          changedTo: formatDate(request.currentDate, formatStrings.defaultDate),
        }}
      />
    ),
    [customerRequestType.status]: (
      <FormattedMessage
        id="request_history_booking_cancelled"
      />
    ),
  })[request.requestType];

  return (
    <div>
      <Tooltip title={fm('booking.activityFeed', 'Activity')}>
        <IconButton ref={iconButtonRef} size="small" onClick={togglePopover}>
          <HistoryIcon />
        </IconButton>
      </Tooltip>

      <Popover
        id="booking-activity"
        keepMounted
        open={open}
        anchorEl={iconButtonRef.current}
        onClose={togglePopover}
        onEnter={onEnter}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <Grid container justify="center">
          <Box m={1} textAlign="center">
            <Typography variant="subtitle1">{fm('request_history')}</Typography>
          </Box>
        </Grid>
        {records.length === 0 ? (
          <Typography variant="caption">
            <Box textAlign="center">
              {fm('no_booking_activity_found', 'No Booking Activity')}
            </Box>
          </Typography>) : (
          records.map(record => (
            <React.Fragment key={`${record.createdAt}-${record.id}`}>
              <List dense>
                <ListItem>
                  <ListItemText
                    primary={getMessage(record)}
                    secondary={<>{fm('updated_at')} {formatDate(record.createdAt, formatStrings.dateSpaceTime)}</>}
                  />
                </ListItem>
              </List>
            </React.Fragment>))
        )}
      </Popover>
    </div>
  );
}
