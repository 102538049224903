import React, { useContext } from 'react';
import { Context as BookingContext } from 'context/bookingContext';
import RequestButton from 'components/Booking/RequestButton';

export default function RequestButtonContainer (props) {
  const { state, setBookingFields } = useContext(BookingContext);

  return (
    <RequestButton
      state={state}
      setBookingFields={setBookingFields}
      booking={props.booking}
    />
  );
}
