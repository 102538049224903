import React, { Suspense, useContext, useEffect } from 'react';
import { HashRouter as Router, Switch } from 'react-router-dom';
import { BookingList } from 'containers/Bookings';
import Cookies from 'views/Cookies';
import PrivateRoute from 'components/PrivateRoute';
import Loader from 'components/Loader';
import PublicRoute from 'components/PublicRoute';
import CookiePolicy from 'components/CookiePolicy';
import Header from 'components/Header';
import Profile from 'views/Profile';
import Invoices from 'views/Invoices';
import { Signup } from 'containers/Signup';
import { ForgotPassword } from 'containers/ForgotPassword';
import { ResetPassword } from 'containers/ResetPassword';
import { Login } from 'containers/Login';
import { CompanyList } from 'containers/CompanyList';
import { appRoutes } from 'utility/enum.js';
import { Context as AuthContext } from 'context/authContext';

const Routes = () => {
  const { state, getUserProfile } = useContext(AuthContext);

  useEffect(() => {
    if (state.token && state.selectedCompany) {
      getUserProfile(state.selectedCompany);
    }
  }, [state.token, state.selectedCompany]);

  return (
    <Suspense fallback={<Loader />}>
      <Router basename="/">
        {state.token && <Header />}
        <Switch>
          <PrivateRoute exact path={appRoutes.home} component={CompanyList} />
          <PrivateRoute exact companyRequired path={appRoutes.bookings} component={BookingList} />
          <PrivateRoute exact path={appRoutes.companiesList} component={CompanyList} />
          <PrivateRoute permissionKey="canCustomerViewProfile" exact companyRequired path={appRoutes.profile} component={Profile} />
          {state.user.fortnoxIntegrated && (
            <PrivateRoute permissionKey="canCustomerViewInvoices" exact path={appRoutes.invoices} component={Invoices} />
          )}
          <PublicRoute exact path={appRoutes.login} component={Login} />
          <PublicRoute exact path={appRoutes.signup} component={Signup} />
          <PublicRoute exact path={appRoutes.forgotPassword} component={ForgotPassword} />
          <PublicRoute exact path={appRoutes.resetPassword} component={ResetPassword} />
          <PrivateRoute exact path={appRoutes.cookiePolicy} component={Cookies} />
          <PrivateRoute path="*" />
        </Switch>
        <CookiePolicy />
      </Router>
    </Suspense>
  );
};

export default Routes;
