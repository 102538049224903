import { SET_AUTH_FIELDS, SET_AUTH_ERROR, SET_SNACKBAR_ERROR, SET_SNACKBAR_INFO, SET_SNACKBAR_SUCCESS, SET_SNACKBAR_WARNING, RESET_SNACKBAR } from 'actions';
import { getSnackbarOpenFields } from 'utility/helper';

const authReducer = (state, action) => {
  switch (action.type) {

  case SET_AUTH_FIELDS:
    return {
      ...state,
      ...action.payload,
      errorMessage: '',
    };

  case SET_AUTH_ERROR:
    return {
      ...state,
      errorMessage: action.payload,
    };

  case SET_SNACKBAR_ERROR:
    return getSnackbarOpenFields(state, action.payload, 'error');

  case SET_SNACKBAR_INFO:
    return getSnackbarOpenFields(state, action.payload, 'info');

  case SET_SNACKBAR_SUCCESS:
    return getSnackbarOpenFields(state, action.payload, 'success');

  case SET_SNACKBAR_WARNING:
    return getSnackbarOpenFields(state, action.payload, 'warning');

  case RESET_SNACKBAR:
    return { ...state, snackbar: { open: false, message: '' } };
  default:
    return state;
  }
};

export default authReducer;
