class Urls {
  constructor () {
    const { REACT_APP_ENV, REACT_APP_LOCAL_SERVER, REACT_APP_API_SUB_DOMAIN = 'test' } = process.env; // eslint-disable-line
    this.devEnv = REACT_APP_ENV === 'development';
    this.isLocal = REACT_APP_LOCAL_SERVER === 'true';
    this.apiSubDomain = this.devEnv ? REACT_APP_API_SUB_DOMAIN : 'backend';
  }

  getApiBaseUrl = () => this.isLocal
    ? 'http://localhost:3000/api/v3/customer_structure'
    : `https://${this.apiSubDomain}.bokningoschema.se/api/v3/customer_structure`;

}

export default new Urls();
