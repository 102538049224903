import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import isValid from 'date-fns/isValid';
import DateFnsUtils from '@date-io/date-fns';

const inputAdornmentProps = { position: 'end' };

const InlineDatePicker = (props) => {

  const disabled = Boolean(props.disabled);

  const onChange = (date, formattedDate) => {
    if (!isValid(date)) return;
    props.onChange(formattedDate, props.name);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        variant={props.variant}
        inputVariant={props.inputVariant}
        label={props.label}
        helperText={props.helperText}
        format={props.format}
        value={props.value}
        onChange={onChange}
        disabled={disabled}
        name={props.name}
        id={props.id}
        InputAdornmentProps={inputAdornmentProps}
        size={props.size}
        fullWidth
        minDate={props.minDate}
        maxDate={props.maxDate}
      />
    </MuiPickersUtilsProvider>
  );
};

InlineDatePicker.defaultProps = {
  inputVariant: 'outlined',
  format: 'yyyy-MM-dd',
  variant: 'inline',
};

export default InlineDatePicker;
