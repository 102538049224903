import createDataContext from 'context/createDataContext';
import invoiceReducer from './reducer';
import axios from 'api/axios';
import { getFormattedCustomerInvoices, getFormattedInvoiceApiReq } from './selector';
import { resetSnackbar, setInvoiceFields, setSnackbarError } from 'actions';
import FileSaver from 'file-saver';
import get from 'lodash/fp/get';

const initialState = {
  customerInvoices: [],
  snackbar: {
    open: false,
    message: '',
  },
  filters: {
    page: 1,
    perPage: 10,
  },
};

const getCustomerInvoices = dispatch => async (prevFilters, company) => {
  try {
    const params = getFormattedInvoiceApiReq({ ...prevFilters, company });
    const { data: { data } } = await axios.get('/customer_invoices', { params });
    const { customerInvoices, filters } = getFormattedCustomerInvoices(data);
    dispatch(setInvoiceFields({ customerInvoices, filters }));
  } catch (error) {
    const errorCode = get('response.data.errors[0].details.code')(error);
    if (errorCode) {
      return dispatch(setSnackbarError(errorCode));
    }
    dispatch(setSnackbarError('snactbarGetIncvoiceError'));
  }
};

const getInvoicePreview = dispatch => async (invoiceId) => {
  try {
    const { data: { data } } = await axios.get(`/invoices/${invoiceId}/preview`);
    fetch(`data:image/png;base64,${data.pdf_file.content}`)
      .then(res => res.blob())
      .then(blob => FileSaver.saveAs(blob, data.pdf_file.name));
  } catch (error) {
    console.log(error);
    dispatch(setSnackbarError('snackbarDownloadInvoiceError'));
  }
};

const resetSnackBarData = dispatch => () => {
  dispatch(resetSnackbar());
};

const setInvoiceFilters = dispatch => (filters) => {
  try {
    dispatch(setInvoiceFields({ filters: { ...filters } }));
  } catch (error) {
    dispatch(setSnackbarError('snackbarSetInvoiceFilterError'));
  }
};

export const { Provider, Context } = createDataContext(
  invoiceReducer,
  {
    getCustomerInvoices,
    getInvoicePreview,
    resetSnackBarData,
    setInvoiceFilters,
  },
  initialState,
);
