import React, { useMemo } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { fm } from 'utility/string';
import {
  Button,
  Grid,
  ValidatorInput,
  CustomizedSnackbars,
  Container,
  Box,
  Typography,
  Alert,
} from 'components/UI';

export default function ForgotPassword (props) {
  const { emailAddress, auth } = props;

  const [emailValidators, emailErrorMessages] = useMemo(() => [
    ['required', 'isEmail'],
    [fm('email_required'), fm('email_invalid')],
  ]);

  return (
    <Container component="main" maxWidth={'xs'}>
      <Grid container justify="center" spacing={2}>
        <Grid xs={12} item>
          <Box mt={5}>
            <Typography component="h1" variant="h5" align="center">
              {fm('forgot_password')}
            </Typography>
          </Box>
        </Grid>
        <Grid xs={12} item>
          <ValidatorForm
            onSubmit={props.handleSubmit}
            instantValidate={false}
          >
            <ValidatorInput
              label={fm('email')}
              onChange={props.handleChangeInput}
              name="email"
              fullWidth
              value={emailAddress}
              validators={emailValidators}
              errorMessages={emailErrorMessages}
              variant="outlined"
            />
            {auth.errorMessage && <Alert color="error">{auth.errorMessage}</Alert>}
            <Box mt={2}>
              <Button variant="contained" color="primary" type="submit" fullWidth>
                {fm('reset_password')}
              </Button>
            </Box>
          </ValidatorForm>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={props.redirectToLogin}>
            {fm('back_to_login')}
          </Button>
        </Grid>
        <CustomizedSnackbars snackbar={auth.snackbar} resetSnackBarData={props.resetSnackBarData} />
      </Grid>
    </Container>
  );
}
