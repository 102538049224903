import React from 'react';
import { Alert, FormattedMessage, Snackbar } from '../UI';

function MuiAlert (props) {
  return <Alert elevation={6} variant="filled" {...props} />;
}

const CustomizedSnackbars = (props) => {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.resetSnackBarData();
  };

  if (!props.snackbar.open) return null;

  return (
    <Snackbar open={props.snackbar.open} autoHideDuration={5000} onClose={handleClose}>
      <MuiAlert onClose={handleClose} severity={props.snackbar.severity}>
        <FormattedMessage id={props.snackbar.message} >
          {props.snackbar.message}
        </FormattedMessage>
      </MuiAlert>
    </Snackbar>
  );
};

export default CustomizedSnackbars;
