import React from 'react';

const cookies = () => (
  <div className="container d-flex justify-content-center mb-5">
    <div className="col-md-8">
      <h1 className="text-center text-primary py-3">Cookies Policy</h1>
      <h6>Denna policy gäller för användning av cookies på https://bokningoschema.se och alla subdomäner som drivs av Bird Vision AB.<br/>
          Har du frågor eller funderingar kring cookies eller säkerheten på någon av våra webbplatser ber vi dig kontakta oss via e-post på info@bokningoschema.se.</h6>
      <p></p>
      <h2>Vad är cookies?</h2>
      <p>En cookie, eller kaka, är en liten textfil som lagras på din dator eller mobila enhet när du besöker en webbsida, och som senare kan hämtas från din dator av webbsidan. Cookies används dels för att underlätta användningen av en webbplats genom att t.ex. komma ihåg ditt val och inställningar och dels för att utveckla webbplatsen och analysera ditt beteende. Vissa funktioner fungerar inte utan cookies.</p>
      <h2>Hur använder vi cookies?</h2>
      <p>På våra webbplatser använder vi cookies för att förbättra din upplevelse, för att samla information för vår marknadsföring och för att kunna utveckla webbplatsen.</p>
      <h2>Acceptera cookies</h2>
      <p>Som besökare kan du själv ange om du vill acceptera att ta emot cookies eller inte. De flesta webbläsare tar emot cookies automatiskt, men det går att ändra i inställningarna så att de inte accepteras eller ger dig ett val om du vill acceptera eller inte.Du bör dock vara medveten om att om cookie-funktionen stängs av finns risk att denna webbplats och våra tjänster inte kommer att kunna nyttjas till fullo.</p>
      <h2>Syftet med cookies</h2>
      <p>Vi använder cookies för att sammanställa anonym, aggregerad statistik som gör det möjligt för oss att förstå hur besökare använder vår webbplats och hjälper oss att förbättra vår webbplats struktur och innehåll.</p>
      <h2>Tredjepartscookies</h2>
      <p>En tredjepartscookie är en cookie som placeras på din enhet för vår räkning av någon annan än oss. Detta förekommer när vi har leverantörer som utför tjänster åt oss, som t.ex. vissa analystjänster eller tjänster som låter dig dela våra sidor i sociala medier. Du kan ställa in hanteringen av cookies i din webbläsare.</p>
      <h2>Blockera Cookies</h2>
      <p>De flesta webbläsare ger dig möjlighet att blockera alla cookies. Använd Hjälp-menyn i din webbläsare för att ta reda på hur du ändrar dina cookie-inställningar.</p>
    </div>
  </div>

);

export default cookies;
