import React, { useContext, useEffect } from 'react';
import {
  Container,
  Box, Grid,
  ToolBarSelect,
  Typography,
  TextField,
  Button,
  List,
  ListItem, ListItemIcon, ListItemText, CustomizedSnackbars, Divider,
} from 'components/UI';
import { GTranslateIcon, CallIcon, ArrowForwardIosIcon, MailIcon } from 'components/UI/icons';
import { fm } from 'utility/string';
import { Context as AuthContext } from 'context/authContext';

const Profile = () => {
  const {
    state,
    setLanguagePersist,
    setAuthFields,
    updateUserProfile,
    resetSnackBarData,
    getUserProfile,
  } = useContext(AuthContext);

  useEffect(() => {
    getUserProfile(state.selectedCompany);
  }, []);

  const onLanguageChange = (event) => {
    setLanguagePersist(event.target.value);
  };

  const selectOptions = [{ value: 'se', label: 'Svenska' }, { value: 'en', label: 'Engelska' }];

  const handleChange = (event) => {
    const { user = {} } = state;

    user[event.target.name] = event.target.value;
    setAuthFields(user);
  };

  const handleSubmit = () => {
    const { user = {} } = state;
    updateUserProfile(user);
  };

  return (
    <>
      <Container component="main" maxWidth="sm">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={8}>
              <Grid container justify="flex-end" alignContent="center" alignItems="center">
                <GTranslateIcon className="md-16 mr-2"/>
                <ToolBarSelect value={state.language} options={selectOptions} onChange={onLanguageChange} />
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              <Box fontWeight="fontWeightBold" mt={2}>{fm('profile_page_heading')}</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {fm('profile_invoice_detail')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="outlined"
              margin="normal"
              fullWidth
              name="name"
              id="name"
              value={state.user.name}
              label={fm('profile_page_name')}
              onChange={handleChange}
              autoFocus
            />
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              name="address"
              id="address"
              value={state.user.address}
              label={fm('profile_page_address')}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              name="zipCode"
              id="zipCode"
              value={state.user.zipCode}
              label={fm('profile_page_zip_code')}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              name="area"
              id="area"
              value={state.user.area}
              label={fm('profile_page_area')}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              variant="outlined"
              fullWidth
              name="mobile"
              id="mobile"
              value={state.user.mobile}
              label={fm('profile_page_mobile')}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  {fm('profile_update_button')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              <Box mt={2}>{fm('profile_page_contact_info')}</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <Box fontWeight="fontWeightBold" mt={2}>{state.companyName}</Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <List component="nav" fullWidth>
              <ListItem key="mobile" component="a" href={`tel:${state.selectedCompany.mobile}`}>
                <ListItemIcon>
                  <CallIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={<Typography color="primary">{state.selectedCompany.mobile}</Typography>} />
                <ListItemIcon>
                  <ArrowForwardIosIcon color="primary" />
                </ListItemIcon>
              </ListItem>
            </List>
            <Divider/>
            <List component="nav">
              <ListItem key="Email" component="a" href={`mailto:${state.selectedCompany.email}`}>
                <ListItemIcon>
                  <MailIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={<Typography color="primary">{state.selectedCompany.email}</Typography>} />
                <ListItemIcon>
                  <ArrowForwardIosIcon color="primary" />
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
      <CustomizedSnackbars snackbar={state.snackbar} resetSnackBarData={resetSnackBarData} />
    </>
  );
};

export default Profile;
