import React, { useRef, useState } from 'react';
import { IconButton } from 'components/UI';
import { MoreHorizIcon } from 'components/UI/icons';
import Popper from './Popper';

export default function RequestButton (props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleToggle} ref={anchorRef}>
        <MoreHorizIcon />
      </IconButton>
      <Popper
        booking={props.booking}
        handleClose={handleClose}
        open={open}
        setBookingFields={props.setBookingFields}
        anchorRef={anchorRef}
      />
    </>
  );
}
