import { RESET_SNACKBAR, SET_INVOICE_FIELDS, SET_SNACKBAR_ERROR, SET_SNACKBAR_INFO, SET_SNACKBAR_SUCCESS, SET_SNACKBAR_WARNING } from 'actions';
import { getSnackbarOpenFields } from 'utility/helper';

const invoiceReducer = (state, action) => {
  switch (action.type) {

  case SET_INVOICE_FIELDS:
    return {
      ...state,
      ...action.payload,
      errorMessage: '',
    };

  case SET_SNACKBAR_ERROR:
    return getSnackbarOpenFields(state, action.payload, 'error');

  case SET_SNACKBAR_INFO:
    return getSnackbarOpenFields(state, action.payload, 'info');

  case SET_SNACKBAR_SUCCESS:
    return getSnackbarOpenFields(state, action.payload, 'success');

  case SET_SNACKBAR_WARNING:
    return getSnackbarOpenFields(state, action.payload, 'warning');

  case RESET_SNACKBAR:
    return { ...state, snackbar: { open: false, message: '' } };

  default:
    return state;
  }
};

export default invoiceReducer;
