import { useMemo } from 'react';
import { fm } from 'utility/string';
import Loader from 'components/Loader';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
  Alert,
  Button,
  Container,
  CustomizedSnackbars,
  Grid,
  Typography,
  Box,
  ValidatorInput,
} from 'components/UI';

const Login = (props) => {
  const { signinCreds, auth } = props;

  const [emailValidators, emailErrorMessages] = useMemo(() => [
    ['required', 'isEmail'],
    [fm('email_required'), fm('email_invalid')],
  ]);

  const [passwordValidators, passwordErrorMessages] = useMemo(() => [
    ['minStringLength:6'],
    [fm('password_6_char_required')],
  ]);

  return (
    <>
      <Container component="main" maxWidth={'xs'}>
        <Grid container justify="center" spacing={2} >
          <Grid xs={12} item>
            <Box mt={5}>
              <Typography component="h1" variant="h5" align="center">
                {fm('login_page_title')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <ValidatorForm
              onSubmit={props.handleSubmit}
              instantValidate={false}
            >
              <ValidatorInput
                label={fm('email')}
                onChange={props.handleChangeInput}
                name="email"
                fullWidth
                value={signinCreds.email}
                validators={emailValidators}
                errorMessages={emailErrorMessages}
                variant="outlined"
              />
              <Box mt={1}>
                <ValidatorInput
                  label={fm('password')}
                  onChange={props.handleChangeInput}
                  name="password"
                  type="password"
                  fullWidth
                  validators={passwordValidators}
                  errorMessages={passwordErrorMessages}
                  value={signinCreds.password}
                  variant="outlined"
                />
              </Box>
              {auth.errorMessage && <Alert color="error">{auth.errorMessage}</Alert>}
              {auth.loading ? <Loader /> : (
                <>
                  {auth.showSendConfirmationEmail && (
                    <Box mt={2}>
                      <Button fullWidth variant="outlined" color="primary" id="cnfrm-btn" onClick={props.onClickSendConfirmationEmail}>
                        {fm('send_confirmation_again')}
                      </Button>
                    </Box>
                  )}
                  <Box mt={2}>
                    <Button fullWidth variant="contained" color="primary" id="login-btn" onClick={props.handleSubmit} type="submit">
                      {fm('login')}
                    </Button>
                  </Box>
                </>
              )}
            </ValidatorForm>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Button onClick={props.redirectSignupPage}>{fm('do_not_have_account_sign_up')}</Button>
              <Button onClick={props.redirectToForgotPassword} color="secondary">{fm('forgot_password')}?</Button>
            </Grid>
          </Grid>

        </Grid>
      </Container>
      <CustomizedSnackbars snackbar={auth.snackbar} resetSnackBarData={props.resetSnackBarData} />
    </>
  );
};

export default Login;
