import React, { useMemo, useEffect } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, ValidatorInput, Container, Box, Typography } from 'components/UI';
import { fm } from 'utility/string';

export default function ResetPassword (props) {
  const { passwords } = props;

  useEffect(() => {
    ValidatorForm.addValidationRule(
      'isPasswordMatch',
      value => value === passwords.password,
    );
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [passwords]);

  const [passwordValidators, passwordErrorMessages] = useMemo(() => [
    ['minStringLength:6'],
    [fm('password_6_char_required')],
  ]);

  const [confirmPasswordValidators, setConfirmpasswordErrorMessages] = useMemo(() => [
    ['isPasswordMatch'],
    [fm('confirm_password_must_match_password')],
  ]);

  return (
    <Container component="main" maxWidth={'xs'}>
      <Grid container justify="center" spacing={2}>
        <Grid xs={12} item>
          <Box mt={5}>
            <Typography component="h1" variant="h5" align="center">
              {fm('reset_password')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ValidatorForm
            onSubmit={props.handleSubmit}
            instantValidate={false}
          >
            <ValidatorInput
              label={fm('password')}
              onChange={props.handleChangeInput}
              name="password"
              type="password"
              validators={passwordValidators}
              errorMessages={passwordErrorMessages}
              value={passwords.password}
              variant="outlined"
            />
            <Box mt={1}>
              <ValidatorInput
                label={fm('confirm_password')}
                onChange={props.handleChangeInput}
                name="confirmPassword"
                type="password"
                validators={confirmPasswordValidators}
                errorMessages={setConfirmpasswordErrorMessages}
                value={passwords.confirmPassword}
                variant="outlined"
              />
            </Box>
            <Box mt={2}>
              <Button variant="contained" color="primary" type="submit">
                {fm('submit')}
              </Button>
            </Box>
          </ValidatorForm>
        </Grid>
        <Grid item xs={12}>
          <Button onClick={props.redirectToLogin}>
            {fm('back_to_login')}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
