import { fm } from 'utility/string';

export const options = [
  {
    title: fm('change_booking_date'),
    getFields: () => ({ showDateChangeModal: true }),
  },
  {
    title: fm('request_cancel_booking'),
    getFields: () => ({ showCancelBookingModal: true }),
  },
];
