/* eslint-disable multiline-comment-style */
import axios from 'axios';
import get from 'lodash/fp/get';
import urls from 'utility/urls';

const configuredAxios = axios.create({
  // test server
  baseURL: urls.getApiBaseUrl(),

  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
    'Content-Type': 'application/json',
  },
});

function onError (error) {
  if (get('response.status')(error) === 401) {
    localStorage.removeItem('token');
    localStorage.removeItem('companyName');
    localStorage.removeItem('user');
    window.location.reload();
  }
  return Promise.reject(error);
}

configuredAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  }
  return config;
}, onError);

// Add a response interceptor
configuredAxios.interceptors.response.use(response => response, onError);

export default configuredAxios;
