import React, { useContext, useEffect, useMemo } from 'react';
import AppBarStyles from 'components/styles/navbar.module.scss';
import { customerHeaderItems } from './items';
import Loader from 'components/Loader';
import TabNavBar from 'components/common/TabNavBar';
import { AppBar, Toolbar, Grid, Button, Typography, Box, Hidden } from 'components/UI';
import { ExpandMoreIcon } from 'components/UI/icons';
import { withRouter } from 'react-router-dom';
import { Context as AuthContext } from 'context/authContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { fm } from 'utility/string';
import { appRoutes } from 'utility/enum';

function Header (props) {
  const { state, signOut } = useContext(AuthContext);
  const { permissions = {} } = state.user;

  const tabs = useMemo(() => customerHeaderItems.filter(item => item.path === customerHeaderItems[0].path ||
    (item.path === customerHeaderItems[1].path && permissions.canCustomerViewInvoices && state.user.fortnoxIntegrated) ||
    (item.path === customerHeaderItems[2].path && permissions.canCustomerViewProfile)), [state.user]);

  const getActiveTab = location => tabs.findIndex(({ path }) => path === location.pathname);
  const [activeTab, setActiveTab] = React.useState(getActiveTab(props.location));
  const navbarTabsData = tabs.map(item => item.title);

  useEffect(() => {
    const tabIndex = getActiveTab(props.location);
    setActiveTab(tabIndex > -1 ? tabIndex : false);
  }, [props.location, props.location.pathname]);

  const onClickHeaderTab = (event, tabIndex) => {
    props.history.push(tabs[tabIndex].path);
  };

  const onClickChangeCompanyIcon = () => {
    props.history.push(appRoutes.companiesList);
  };

  return (
    <>
      <AppBar id="main-app-bar" position="fixed" elevation={0} classes={{ colorPrimary: AppBarStyles.Appbar }}>
        <Toolbar variant="dense" disableGutters>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Box ml={2}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h6">{state.selectedCompany?.name}</Typography>
                      {state.selectedCompany && (
                        <Box className="pointer">
                          <ExpandMoreIcon onClick={onClickChangeCompanyIcon} fontSize="small" className="pointer" />
                        </Box>)
                      }
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item>
              <TabNavBar tabs={navbarTabsData} activeTab={activeTab} setActiveTab={onClickHeaderTab} />
            </Grid>
            <Grid item>
              <Box mr={2}>
                <Grid container justify="flex-end">
                  <Grid container alignItems="center" justify="flex-start">
                    <Grid item >
                      <div className={'classes.grow'} />
                      <Loader />
                    </Grid>
                    <Hidden mdUp>
                      <Grid item>
                        <Typography onClick={signOut}>
                          <Button color="secondary" startIcon={<ExitToAppIcon />}/>
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Hidden mdDown>
                      <Grid item>
                        <Typography onClick={signOut}>
                          <Button color="secondary" startIcon={<ExitToAppIcon />}>{fm('booking.logout', 'Log out')}</Button>
                        </Typography>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default withRouter(Header);
