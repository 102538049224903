export const SET_APP_FIELDS = 'SET_APP_FIELDS';
export const SET_AUTH_FIELDS = 'SET_AUTH_FIELDS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_CONFIRMATION_DIALOG_FIELDS = 'SET_CONFIRMATION_DIALOG_FIELDS';
export const SET_INVOICE_FIELDS = 'SET_INVOICE_FIELDS';
export const SET_BOOKING_FIELDS = 'SET_BOOKING_FIELDS';
export const SET_SNACKBAR_INFO = 'SET_SNACKBAR_INFO';
export const SET_SNACKBAR_SUCCESS = 'SET_SNACKBAR_SUCCESS';
export const SET_SNACKBAR_ERROR = 'SET_SNACKBAR_ERROR';
export const SET_SNACKBAR_WARNING = 'SET_SNACKBAR_WARNING';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';

export const setAppFields = payload => ({ type: SET_APP_FIELDS, payload });
export const setAuthFields = payload => ({ type: SET_AUTH_FIELDS, payload });
export const setAuthError = payload => ({ type: SET_AUTH_ERROR, payload });
export const setConfirmationDialogFields = payload => ({ type: SET_CONFIRMATION_DIALOG_FIELDS, payload });
export const setInvoiceFields = payload => ({ type: SET_INVOICE_FIELDS, payload });
export const setBookingFields = payload => ({ type: SET_BOOKING_FIELDS, payload });
export const setSnackbarSuccess = payload => ({ type: SET_SNACKBAR_SUCCESS, payload });
export const setSnackbarError = payload => ({ type: SET_SNACKBAR_ERROR, payload });
export const setSnackbarInfo = payload => ({ type: SET_SNACKBAR_INFO, payload });
export const setSnackbarWarning = payload => ({ type: SET_SNACKBAR_WARNING, payload });
export const resetSnackbar = payload => ({ type: RESET_SNACKBAR, payload });
