import React, { useEffect, useContext, useCallback, useState } from 'react';
import ResetPassword from 'views/ResetPassword';
import { Context as AuthContext } from 'context/authContext';
import { appRoutes } from 'utility/enum';
import { parseQueryString } from 'utility/string';

const initialState = { password: '', confirmPassword: '' };

export default function ResetPasswordContainer (props) {
  const { state, requestChangePassword } = useContext(AuthContext);
  const [passwords, setPasswords] = useState(initialState);
  const [resetToken, setResetToken] = useState({});

  useEffect(() => {
    const resetPasswordTokenObj = parseQueryString(props.location.search);
    setResetToken(resetPasswordTokenObj);
    return () => {
      setPasswords(initialState);
    };
  }, []);

  const handleChangeInput = useCallback((event) => {
    const { name, value } = event.target;
    const newPasswordData = { ...passwords, [name]: value };
    console.log(newPasswordData);
    setPasswords(newPasswordData);
  }, [passwords]);

  const handleSubmit = () => {
    const resetPasswordData = { ...passwords, ...resetToken };
    requestChangePassword(resetPasswordData, props.history);
  };

  const redirectToLogin = useCallback(() => {
    props.history.push(appRoutes.login);
  }, []);

  return (
    <ResetPassword
      handleSubmit={handleSubmit}
      redirectToLogin={redirectToLogin}
      passwords={passwords}
      handleChangeInput={handleChangeInput}
      auth={state}
    />
  );
}
