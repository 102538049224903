import React, { useCallback } from 'react';
import { DateRange, FiberManualRecordIcon } from 'components/UI/icons';
import { fm, getRecurringMessage } from 'utility/string';
import { bookingStatusTypes, customerRequestType } from 'utility/enum';
import { RequestButton, RequestHistoryPopover } from 'containers/Bookings';
import styles from 'components/styles/colors.module.scss';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  ListItemSecondaryAction,
  Chip,
  Tooltip,
} from 'components/UI';

const bookingStatusColors = {
  Active: { root: styles.active },
  Canceled: { root: styles.canceled },
  Completed: { root: styles.completed },
};

export default function BookingListRow (props) {
  const { booking, user, setBookingFields } = props;
  const { permissions = {} } = user;
  const { requests } = booking;

  const handleClickDateChangeRequestIcon = useCallback(() => {
    setBookingFields({ showDateChangeModal: true, booking });
  }, [booking]);

  const handleClickCancellationRequestIcon = useCallback(() => {
    setBookingFields({ showCancelBookingModal: true, booking });
  }, [booking]);

  return (
    <Grid item xs={12}>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <Chip color="default" label={fm(booking.status.toLowerCase())} classes={bookingStatusColors[booking.status]} />
          </ListItemIcon>
          <ListItemText
            primary={(
              <Box component="span" display="flex" justifyContent="space-between">
                <Box ml={2}>
                  {booking.startDate || booking.date} | {permissions.canCustomerViewBookingTime && booking.startTime}-{booking.endTime}
                </Box>
                {requests[customerRequestType.date] && (
                  <Tooltip title={fm('you_have_put_date_change_request')}>
                    <Box mr={3}>
                      <DateRange onClick={handleClickDateChangeRequestIcon} />
                    </Box>
                  </Tooltip>
                )}
                {requests[customerRequestType.status] && (
                  <Tooltip title={fm('you_have_put_cancellation_request')}>
                    <Box mr={3}>
                      <FiberManualRecordIcon color="error" onClick={handleClickCancellationRequestIcon} />
                    </Box>
                  </Tooltip>
                )}
                {booking.requestHistories && <RequestHistoryPopover booking={booking} />}
              </Box>
            )}
            secondary={
              <>
                {booking.repeatType && (
                  <Typography component="span"><Box ml={1}>{getRecurringMessage(booking)}</Box></Typography>
                )}
                <Typography component="span">{booking.address}</Typography>
              </>
            }
          />
          <ListItemSecondaryAction>
            {booking.status === bookingStatusTypes.active && (
              <RequestButton booking={booking} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Divider />
    </Grid>
  );
}
