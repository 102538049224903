import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function CustomDialog (props) {
  return (
    <Dialog
      open={props.open}
      className={props.className}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      onClose={props.onClose}
      onEnter={props.onEnter}
      onExited={props.onExited}
      TransitionComponent={props.TransitionComponent}
    >
      {props.children}
    </Dialog>
  );
}

CustomDialog.defaultProps = {
  open: false,
};

export {
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
};
