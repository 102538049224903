import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context as AuthContext } from '../../context/authContext';
import { appRoutes } from '../../utility/enum';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { state } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (state.token) {
          return <Redirect to={{ pathname: appRoutes.bookings, search: props.location.search, state: { from: props.location } }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;
