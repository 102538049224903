import React from 'react';

const Loader = ({ show }) => !show ? null : (
  <div className="text-center">
    <div className="spinner-grow text-primary" role="status" />
    <div className="spinner-grow text-secondary" role="status" />
    <div className="spinner-grow text-success" role="status" />
  </div>);

export default Loader;
