import React, { useEffect, useContext, useCallback, useState } from 'react';
import Signup from '../../views/Signup';
import { Context as AuthContext } from 'context/authContext';
import { appRoutes } from 'utility/enum';

const initialState = { email: '', password: '' };

export default function SignupContainer (props) {
  const { state, requestSignup, setAuthError } = useContext(AuthContext);
  const [signupData, setSignupData] = useState(initialState);

  useEffect(() => () => {
    setSignupData(initialState);
    setAuthError('');
  }, []);

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    const newSignupData = { ...signupData, [name]: value };
    setSignupData(newSignupData);
  };

  const handleSubmit = () => {
    requestSignup({ ...signupData }, props.history);
  };

  const redirectToLogin = useCallback(() => {
    props.history.push(appRoutes.login);
  }, []);

  return (
    <Signup
      handleSubmit={handleSubmit}
      redirectToLogin={redirectToLogin}
      signupData={signupData}
      handleChangeInput={handleChangeInput}
      auth={state}
    />
  );
}
