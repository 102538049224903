import React from 'react';
import { FormattedMessage } from 'react-intl';
import camelize from 'camelize';
import qs from 'qs';
import trim from 'lodash/fp/trim';

export const fm = (id, msg, options = {}) => <FormattedMessage id={id} defaultMessage={msg} {...options} />;

export const parseQueryString = str => camelize(qs.parse(str, { ignoreQueryPrefix: true }));

export const getRecurringMessage = (booking, defaultValue = '') => {
  try {
    return <>{booking.repeatInterval && (fm('repeat_every') || '')} {booking.repeatInterval && (fm(`${booking.repeatInterval}`) || '')} {(fm(`${booking.repeatType}`) || '')} {booking.repeatInterval && (fm('on') || '')}  {booking.repeatDays.map((day, idx) => booking.repeatDays[idx + 1] ? day.concat(', ') : fm(`${day}`))}</>;
  } catch (err) {
    return defaultValue;
  }
};

export const deepTrim = (value) => {
  if (!value) return value;

  const mapObj = () => {
    const valueCopy = Object.assign(Array.isArray(value) ? [] : {}, value);
    for (const key in value) {
      valueCopy[key] = deepTrim(valueCopy[key]);
    }
    return valueCopy;
  };

  const mapFun = { string: trim, object: mapObj }[typeof value];
  return mapFun ? mapFun(value) : value;
};
