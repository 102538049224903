import React, { useEffect, useContext, useState } from 'react';
import Login from 'views/Login';
import { Context as AuthContext } from 'context/authContext';
import { appRoutes } from 'utility/enum';

const initialState = { email: '', password: '' };

export default function LoginContainer (props) {
  const [signinCreds, setSigninCreds] = useState(initialState);
  const {
    state,
    setAuthFields,
    setAuthError,
    requestSignin,
    resetSnackBarData,
    requestSendConfirmationEmail,
  } = useContext(AuthContext);

  useEffect(() => () => {
    setSigninCreds(initialState);
    setAuthError('');
    setAuthFields({ showSendConfirmationEmail: false });
  }, []);

  const onClickSendConfirmationEmail = () => {
    requestSendConfirmationEmail(signinCreds.email);
  };

  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    const newSignupData = { ...signinCreds, [name]: value };
    setSigninCreds(newSignupData);
  };

  const handleSubmit = () => {
    requestSignin({ ...signinCreds }, props.history);
  };

  const redirectSignupPage = () => {
    props.history.push(appRoutes.signup);
  };

  const redirectToForgotPassword = () => {
    props.history.push(appRoutes.forgotPassword);
  };

  return (
    <Login
      handleSubmit={handleSubmit}
      resetSnackBarData={resetSnackBarData}
      signinCreds={signinCreds}
      handleChangeInput={handleChangeInput}
      redirectSignupPage={redirectSignupPage}
      redirectToForgotPassword={redirectToForgotPassword}
      onClickSendConfirmationEmail={onClickSendConfirmationEmail}
      auth={state}
    />
  );
}
