import createDataContext from '../createDataContext';
import bookingReducer from './reducer';
import axios from '../../api/axios';
import {
  getFormattedBookings,
  getFormattedBookingsApiReq,
  getBookingRequestBody,
  getOrderRequestBody,
  getVirtualOrderRequestBody,
} from './selector';
import { resetSnackbar, setBookingFields, setSnackbarError, setSnackbarSuccess } from '../../actions';
import camelize from 'camelize';
import orderBy from 'lodash/fp/orderBy';
import { getStandardDate } from '../../utility/dateTime';

const initialState = {
  loading: false,
  records: [],
  filters: {
    startDate: getStandardDate(),
    page: 1,
    perPage: 10,
  },
  requestHistory: [],
  snackbar: {
    open: false,
    message: '',
  },
};

const getCustomerCompanyBookings = dispatch => async (prevFilters, company) => {
  try {
    dispatch(setBookingFields({ loading: true }));
    const params = getFormattedBookingsApiReq({ ...prevFilters, company });
    const { data: { data } } = await axios.get('/order_customer_requests/orders', { params });
    const { records, filters } = getFormattedBookings(data.orders);
    dispatch(setBookingFields({ loading: false, records, filters: { ...prevFilters, filters } }));
  } catch (error) {
    dispatch(setBookingFields({ loading: false }));
    dispatch(setSnackbarError('snackbarGetBookingError'));
  }
};

const requestCreateVirtualOrder = async (params) => {
  const response = await axios.post('/order_customer_requests/create_order', params);
  const { order } = response.data.data;
  return order;
};

const getCustomerBooking = dispatch => async (seqNum) => {
  try {
    const { data: { data } } = await axios.get(`/bookings/${seqNum}`);
    const booking = camelize(data);
    dispatch(setBookingFields({ booking }));
  } catch (error) {
    dispatch(setSnackbarError('snackbarGetBookingError'));
  }
};

const requestBookingDateChange = dispatch => async (requestData) => {
  try {
    const requestBody = getBookingRequestBody(requestData);
    await axios.post('/requests', requestBody);
    dispatch(setBookingFields({ showDateChangeModal: false, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar_request_sent'));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_update_booking_date_error'));
  }
};

const requestOrderDateChange = dispatch => async (requestData) => {
  try {
    const requestBody = getOrderRequestBody(requestData);
    await axios.post('/order_customer_requests', requestBody);
    dispatch(setBookingFields({ showDateChangeModal: false, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar_request_sent'));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_update_booking_date_error'));
  }
};

const requestVirtualOrderDateChange = dispatch => async (data) => {
  try {
    const params = {
      project_id: data.projectId,
      order_date: data.date,
      company_id: data.companyId,
    };
    const order = await requestCreateVirtualOrder(params);
    const requestBody = getVirtualOrderRequestBody(data, order.id);
    await axios.post('/order_customer_requests', requestBody);
    dispatch(setBookingFields({ showDateChangeModal: false, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar_request_sent'));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_update_booking_date_error'));
  }
};

const requestCancelBooking = dispatch => async (requestData) => {
  try {
    const requestBody = getBookingRequestBody(requestData);
    await axios.post('/requests', requestBody);
    dispatch(setBookingFields({ showCancelBookingModal: false, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar_request_sent'));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestCancelOrder = dispatch => async (requestData) => {
  try {
    const requestBody = getOrderRequestBody(requestData);
    await axios.post('/order_customer_requests', requestBody);
    dispatch(setBookingFields({ showCancelBookingModal: false, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar_request_sent'));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestCancelVirtualOrder = dispatch => async (requestData) => {
  try {
    const params = {
      project_id: requestData.projectId,
      order_date: requestData.date,
      company_id: requestData.companyId,
    };
    const order = await requestCreateVirtualOrder(params);
    const requestBody = getVirtualOrderRequestBody(requestData, order.id);
    await axios.post('/order_customer_requests', requestBody);
    dispatch(setBookingFields({ showCancelBookingModal: false, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar_request_sent'));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestUpdateBookingChangeRequest = (dispatch, state) => async (request, nextRequest, companyId) => {
  try {
    const requestBody = getBookingRequestBody({ ...request, ...nextRequest });
    const response = await axios.put(`/requests/${request.id}?company_id=${companyId}`, requestBody);
    const requestData = camelize(response.data.data);
    const booking = {
      ...state.booking,
      requests: {
        ...state.booking.requests,
        [requestData.requestType]: requestData,
      },
    };
    dispatch(setBookingFields({
      booking,
      showCancelBookingModal: false,
      showDateChangeModal: false,
      refreshAllBookings: {},
    }));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestUpdateOrderChangeRequest = (dispatch, state) => async (request, nextRequest) => {
  try {
    const requestBody = getBookingRequestBody({ ...request, ...nextRequest });
    const response = await axios.put(`/order_customer_requests/${request.id}`, requestBody);
    const requestData = camelize(response.data.data);
    const booking = {
      ...state.booking,
      requests: {
        ...state.booking.requests,
        [requestData.requestType]: requestData,
      },
    };
    dispatch(setBookingFields({
      booking,
      showCancelBookingModal: false,
      showDateChangeModal: false,
      refreshAllBookings: {},
    }));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestBookingChangeRequestDetails = (dispatch, state) => async (requestId, companyId) => {
  try {
    const response = await axios.get(`/requests/${requestId}?company_id=${companyId}`);
    const requestData = camelize(response.data.data);
    const booking = {
      ...state.booking,
      requests: {
        ...state.booking.requests,
        [requestData.requestType]: requestData,
      },
    };
    dispatch(setBookingFields({ booking }));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestOrderChangeRequestDetails = (dispatch, state) => async (requestId, companyId) => {
  try {
    const params = { company_id: companyId };
    const response = await axios.get(`/order_customer_requests/${requestId}`, { params });
    const requestData = camelize(response.data.data);
    const booking = {
      ...state.booking,
      requests: {
        ...state.booking.requests,
        [requestData.requestType]: requestData,
      },
    };
    dispatch(setBookingFields({ booking }));
  } catch (error) {
    dispatch(setSnackbarError('snackbar_cancel_booking_request_error'));
  }
};

const requestDeleteBookingChangeRequest = (dispatch, state) => async (request, companyId) => {
  try {
    await axios.delete(`/requests/${request.id}?company_id=${companyId}`);
    const booking = {
      ...state.booking,
      requests: {
        ...state.booking.requests,
        [request.requestType]: null,
      },
    };
    dispatch(setBookingFields({
      booking,
      showCancelBookingModal: false,
      showDateChangeModal: false,
      refreshAllBookings: {},
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_delete_booking_request_error'));
  }
};

const requestDeleteOrderChangeRequest = (dispatch, state) => async (request, companyId) => {
  try {
    const params = {
      company_id: companyId,
    };
    await axios.delete(`/order_customer_requests/${request.id}`, { params });
    const booking = {
      ...state.booking,
      requests: {
        ...state.booking.requests,
        [request.requestType]: null,
      },
    };
    dispatch(setBookingFields({
      booking,
      showCancelBookingModal: false,
      showDateChangeModal: false,
      refreshAllBookings: {},
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_delete_booking_request_error'));
  }
};

const requestBookingRequestsHistory = dispatch => async (bookingSequenceNum, companyId) => {
  try {
    const params = { company_id: companyId };
    const response = await axios.get(`/bookings/${bookingSequenceNum}/request_histories`, { params });
    const requestHistory = orderBy(d => new Date(d.createdAt), ['desc'])(camelize(response.data.data));
    dispatch(setBookingFields({ requestHistory }));
  } catch (err) {
    console.log(err);
    dispatch(setSnackbarError('snackbar_get_request_history_error'));
  }
};

const requestOrderRequestsHistory = dispatch => async (orderId, companyId) => {
  try {
    const params = { company_id: companyId, id: orderId };
    const response = await axios.get('/order_customer_requests/request_histories', { params });
    const requestHistory = orderBy(d => new Date(d.createdAt), ['desc'])(camelize(response.data.data));
    dispatch(setBookingFields({ requestHistory }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_get_request_history_error'));
  }
};

const setBookingFilters = dispatch => (filters) => {
  try {
    dispatch(setBookingFields({ filters: { ...filters } }));
  } catch (error) {
    dispatch(setSnackbarError('Failed to set booking filters'));
  }
};

const setBookingFieldsThunk = dispatch => (fields) => {
  dispatch(setBookingFields({ ...fields }));
};

const resetSnackBarData = dispatch => () => {
  dispatch(resetSnackbar());
};

export const { Provider, Context } = createDataContext(
  bookingReducer,
  {
    getCustomerCompanyBookings,
    getCustomerBooking,
    setBookingFilters,
    resetSnackBarData,
    requestBookingDateChange,
    requestOrderDateChange,
    requestCancelBooking,
    requestCancelOrder,
    requestVirtualOrderDateChange,
    requestCancelVirtualOrder,
    requestBookingChangeRequestDetails,
    requestDeleteBookingChangeRequest,
    requestDeleteOrderChangeRequest,
    requestUpdateBookingChangeRequest,
    requestUpdateOrderChangeRequest,
    requestBookingRequestsHistory,
    requestOrderRequestsHistory,
    requestOrderChangeRequestDetails,
    setBookingFields: setBookingFieldsThunk,
  },
  initialState,
);
