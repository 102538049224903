import {
  SET_APP_FIELDS,
  SET_CONFIRMATION_DIALOG_FIELDS,
} from '../../actions';

const authReducer = (state, action) => {
  switch (action.type) {

  case SET_APP_FIELDS:
    return {
      ...state,
      ...action.payload,
    };

  case SET_CONFIRMATION_DIALOG_FIELDS:
    return {
      ...state,
      confirmationDialog: {
        ...state.confirmationDialog,
        ...action.payload,
      },
    };

  default:
    return state;
  }
};

export default authReducer;
