import camelize from 'camelize';
import { deepTrim } from 'utility/string';
import keyBy from 'lodash/fp/keyBy';
import { permissionTypeFieldNames } from 'utility/enum';

const getFormattedEmployeePermission = permissions => permissions.reduce((res, permssion) => {
  res[permissionTypeFieldNames[permssion.permission_number]] = permssion.status;
  return res;
}, {});

export const getFormattedUserDetails = userData => ({
  user: {
    ...camelize(userData),
    fortnoxIntegrated: userData.integration_type === 'fortnox',
    permissions: getFormattedEmployeePermission(userData.permissions),
  },
});

export const getFormattedAvailableCompaniesData = companies => companies.map(company => ({
  ...company,
  messageRules: keyBy('rule')(company.messageRules),
}));

export const getFormattedUserProfileUpdateApiReq = user => deepTrim(({
  customer: {
    name: user.name,
    email: user.email,
    mobile: user.mobile,
    telephone: user.telephone,
    address: user.address,
    area: user.area,
    city: user.city,
    zip_code: user.zipCode,
  },
}));

export const getResetPasswordRequestBody = data => deepTrim(({
  customer_login: {
    password: data.password,
    password_confirmation: data.confirmPassword,
    reset_password_token: data.resetPasswordToken,
  },
}));
