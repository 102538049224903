import React, { useContext } from 'react';
import { Context as AppContext } from 'context/appContext';
import ConfirmationDialog from 'components/common/ConfirmationDialog';

export default function BookingDateChangeModalContainer () {
  const { state, setConfirmationDialogFields } = useContext(AppContext);

  return (
    <ConfirmationDialog
      confirmationDialog={state.confirmationDialog}
      setConfirmationDialogFields={setConfirmationDialogFields}
    />
  );
}
