import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './style.css';

const msg = 'We use cookies to give you the best experience possible, by continuing we will assume you are on board.';

function CookiePolicy () {
  const [rand, setRandValue] = useState(); // eslint-disable-line
  const cookie = document.cookie.split(';').find(c => c.trim().startsWith('_cca'));

  if (cookie) {
    const [name, value] = cookie.trim().split('=');
    if (cookie && name === '_cca' && value === 'si') return null;
  }

  const onAcceptPolicy = () => {
    const date = new Date();
    const expireTime = new Date(date.getTime() + 7776000000);
    document.cookie = `_cca=si; expires=${expireTime};path=/`;
    setRandValue(Math.random());
  };

  return (
    <div className="cookie-policy p-3 rounded-lg w-100">
      <div className="d-flex align-self-center">
        <FormattedMessage id="cookie.message" defaultMessage={msg}/>
        <Link target="_blank" rel="noopener noreferrer" to="/cookie-policy">
          <FormattedMessage id="learn_more" defaultMessage="Learn More"/>
        </Link>
        <button className="ml-3" onClick={onAcceptPolicy}>OK</button>
      </div>
    </div>
  );
}

export default CookiePolicy;
