import React, { useEffect, useContext, useCallback, useState } from 'react';
import ForgotPassword from 'views/ForgotPassword';
import { Context as AuthContext } from 'context/authContext';
import { appRoutes } from 'utility/enum';

export default function SignupContainer (props) {
  const { state, requestResetPasswordLink, resetSnackBarData, setAuthError } = useContext(AuthContext);
  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => () => {
    setEmailAddress('');
    setAuthError('');
  }, []);

  const handleChangeInput = useCallback((event) => {
    const { value } = event.target;
    setEmailAddress(value);
  }, []);

  const handleSubmit = () => {
    requestResetPasswordLink(emailAddress, props.history);
  };

  const redirectToLogin = useCallback(() => {
    props.history.push(appRoutes.login);
  }, []);

  return (
    <ForgotPassword
      handleSubmit={handleSubmit}
      redirectToLogin={redirectToLogin}
      emailAddress={emailAddress}
      handleChangeInput={handleChangeInput}
      resetSnackBarData={resetSnackBarData}
      auth={state}
    />
  );
}
