import React, { useContext } from 'react';
import { Context as BookingContext } from 'context/bookingContext';
import RequestHistoryPopover from 'components/Booking/RequestHistoryPopover';
import { Context as AuthContext } from 'context/authContext';

export default function RequestHistoryPopoverContainer (props) {
  const { state, requestBookingRequestsHistory, requestOrderRequestsHistory } = useContext(BookingContext);
  const { state: { selectedCompany } } = useContext(AuthContext);

  return (
    <RequestHistoryPopover
      state={state}
      selectedCompany={selectedCompany}
      requestHistory={state.requestHistory}
      requestBookingRequestsHistory={requestBookingRequestsHistory}
      requestOrderRequestsHistory={requestOrderRequestsHistory}
      booking={props.booking}
    />
  );
}
