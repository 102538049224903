import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context as AuthContext } from '../../context/authContext';
import { appRoutes } from '../../utility/enum';

const PrivateRoute = ({ component: Component, companyRequired, permissionKey, ...rest }) => {
  const { state } = useContext(AuthContext);
  const { user = {} } = state;
  const { permissions = {} } = user;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!state.token) {
          // not logged in so redirect to login page with the return url
          return <Redirect to={{ pathname: appRoutes.login, search: props.location.search, state: { from: props.location } }} />;
        }

        if ((companyRequired && !state.selectedCompany) || permissions[permissionKey] === false) {
          return <Redirect to={appRoutes.companiesList} />;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
