import createDataContext from '../createDataContext';
import authReducer from './reducer';
import {
  setConfirmationDialogFields,
  setAppFields,
} from 'actions';
import {
} from './selector';

const initialState = {
  confirmationDialog: {
    open: false,
    message: 'Are you sure want to continue?',
    title: 'Confirm',
  },
};

const setAppFieldsDispatcher = dispatch => (fields) => {
  dispatch(setAppFields(fields));
};

const setConfirmationDialogFieldsDispatcher = dispatch => (fields) => {
  dispatch(setConfirmationDialogFields(fields));
};


export const { Provider, Context } = createDataContext(
  authReducer,
  {
    setConfirmationDialogFields: setConfirmationDialogFieldsDispatcher,
    setAppFields: setAppFieldsDispatcher,
  },
  initialState,
);
